import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import DownArrow from "../../../../assets/icons/wild/arrow-down-nav.svg"
import { isIpad } from "../../../../utils/general"
import NavigationShop from "./navigationshop"

const tabletFold = 1024

const Nav = styled.nav`
  display: none;
  .master-nav-ul {
    #products {
      display: none;
    }
  }
  @media (min-width: ${tabletFold}px) {
    display: inline-block;
    position: static;
    width: 100%;
    height: auto;
    padding: 0;
    margin-left: 16px;
    .navigation-logo {
      display: none;
    }
  }
  //for debugging
  [aria-expanded="true"] {
    //border: 1px orange solid;
  }
  [aria-expanded="false"] {
    //border: 1px green solid;
  }
`

const SubItemWrapper = styled(Box)`
  width: 100%;
`

const SubItem = styled(Box)`
  @media (min-width: 768px) and (max-width: 930px) {
    .subtext {
      max-width: 174px;
      br {
        display: none;
      }
    }
  }
  .br-xxl {
    display: none;
    @media (min-width: 1289px) {
      display: block;
    }
  }
`

const NavUl = styled.ul`
  li:first-of-type {
    position: initial;
  }
`

const ListItem = styled.li`
  @media (min-width: 768px) {
    position: relative;
    display: inline-block;
    padding: 27px 1.5% 24px;
    vertical-align: top;
    color: #041C1B;
    > a {
      border-bottom: 1px solid transparent;
    }
    &:focus,
    &:hover {
      > a {
        color: #041C1B;
      }
      > a[href="#"] {
        cursor: default;
      }
    }
    &.has-subnav {
      &:focus,
      &.hover {
        .arrow-down {
          transform: rotate(180deg);
          svg {
            transform: rotate(180deg);
          }
        }
        .dropdown {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &:focus,
    &.hover {
      > a {
        border-bottom: 1px solid rgba(27, 27, 27, 0.4);
      }
    }
  }
  a {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: ${theme.wild.font.grilli.italic};
    color: #041C1B;
  }
  @media (min-width: 1024px) {
    padding: 29px 4px;
  }
  @media (min-width: 1180px) {
    padding: 29px 8px;
    a {
      font-size: 16px;
    }
  }
  @media (min-width: 1240px) {
    padding: 29px 13px;
  }
  @media (min-width: 1520px) {
    padding: 29px 17px;
  }
`
const InternalNavLink = styled.a`
  font-family: ${theme.wild.font.grilli.italic};
  color: #041C1B;
  display: inline-block;
  text-align: center;
  &:focus,
  &:hover {
    color: #041C1B;
  }
`
const ArrowSection = styled.span`
  display: inline-block;
  width: 8px;
  margin-left: 2px;
  position: relative;
  top: -2px;
  .arrow-down {
    transition: transform ease-in-out 0.15s;
  }

  @media (min-width: 1024px) and (max-width: 1100px) {
    width: 6px;
  }
  @media (min-width: 1280px) {
    margin-left: 6px;
  }
`
const DropdownMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 72px;
  background: transparent;
  z-index: 12;
  transition:
    visibility 0s,
    opacity 0.1s ease-in-out;
  left: 50%;
  width: 206px;
  transform: translateX(-50%);
  border-top: 4px solid transparent;
  &.full-dropdown {
    left: 0;
    width: 100%;
    transform: none;

    /* for development */
    /* visibility: visible;
    opacity: 1; */
  }
  @media (min-width: 1024px) {
    top: 79px;
  }
`
const DropdownWrapper = styled.div`
  background: ${theme.color.white};
  border-radius: 4px;
  box-shadow: 0 11px 15px 0 rgba(0, 87, 82, 0.06);
`
const MainBox = styled(Box)`
  a:focus,
  a:hover {
    .name-section {
      h4 {
        color: ${theme.wild.color.master.primary.default} !important;
        text-decoration: underline;
      }
    }
  }
  img {
    width: 100%;
    /* @media (min-width: 1024px){ */
    &.Scalp {
      max-width: 98px;
      margin-left: auto;
      margin-right: auto;
    }
    &.Boosters {
      max-width: 98px;
      margin-left: auto;
      margin-right: auto;
    }
    /* } */
  }
`
const NavItem = styled.div`
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 ${theme.spacing.small};
    background: #f8f8f5;
    border-radius: 4px;
    &:hover {
      /* create overlay effect */
      background: linear-gradient(
          rgba(100, 100, 100, 0.05),
          rgba(100, 100, 100, 0.05)
        ),
        linear-gradient(#f8f8f5, #f8f8f5);
    }
  }

  &.products-nav-item {
    a {
      justify-content: initial;
      height: 145px;
      background: ${theme.wild.color.master.primary.alt};
      &:hover {
        background: #d2ede8;
      }
    }
    &.nav-item-mens {
      > a {
        background: ${theme.wild.color.master.secondary.alt};
        &:hover {
          background: #cbe6ef;
        }
      }
      .product-title {
        font-family: ${theme.wild.font.gotham.black} !important;
        text-transform: uppercase;
        ${queries.medium`
          font-size: 28px;  
          line-height: 90%;
          margin-bottom: 4px;
        `}
      }
      .mens-site-nav-button {
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
        @media (min-width: 768px) and (max-width: 930px) {
          font-size: 13px;
          .right {
            display: none;
          }
        }
      }
    }
  }

  &.shop-nav-item {
  }
`
const SubNavItem = styled.div`
  a {
    display: flex;
    align-items: center;
    height: 112px;
    padding: 0 ${theme.spacing.small};
    border-radius: 4px;
    background: #f8f8f5;
    &:hover {
      /* create overlay effect */
      background: linear-gradient(
          rgba(100, 100, 100, 0.05),
          rgba(100, 100, 100, 0.05)
        ),
        linear-gradient(#f8f8f5, #f8f8f5);
    }
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  button {
    width: 100%;
  }
`

if (typeof window !== `undefined`) {
  // Polyfill for IE9+
  if (!window.Element.prototype.matches)
    window.Element.prototype.matches =
      window.Element.prototype.msMatchesSelector
  if (!window.Element.prototype.closest)
    window.Element.prototype.closest = function (selector) {
      var el = this
      while (el) {
        if (el.matches(selector)) {
          return el
        }
        el = el.parentElement
      }
    }
}

const addActiveItemClass = (el) => {
  if (el && el.classList && el.classList.contains("has-subnav")) {
    el.classList.add("hover")
  }
  handleExpand(el)
}

const removeActiveItemClass = (el, preventClick) => {
  const mouseLeftItem = hasHover(el)
  if (!!mouseLeftItem) {
    mouseLeftItem.classList.remove("hover")
  }
  if (preventClick) {
    el.preventDefault()
  }
  handleExpand(el)
}

const handleExpand = (el) => {
  if (el?.id) {
    ;[...document.querySelectorAll("nav [aria-expanded]")].forEach((a) => {
      const isHover = a?.parentElement?.classList?.contains("hover")
      a.ariaExpanded = isHover ? "true" : "false"
    })
  }
}

const onFocus = (el) => {
  handleExpand(el)
}

const onBlur = (el) => {
  handleExpand(el)
}

const removeAllActiveItemClass = (e) => {
  const hover = document.getElementsByClassName("hover")
  let i = 0
  for (; i < hover.length; i++) {
    hover[i].classList.remove("hover")
  }
}

const mobileArrowClick = (e, item) => {
  const listItem = document.getElementById(item.name)
  removeAllActiveItemClass()
  addActiveItemClass(listItem)
}

const hasHover = (e) => e.target.closest(".has-subnav.hover")
const buildCls = (subitem, hoveredItem) => {
  return subitem.subtext ? "has-subtext" : "no-subtext"
}

const trackExpanded = (text) => {
  segmentEvent("Navigation Expanded", {
    module_location: "navigation",
    text,
  })
}

const trackClicked = (text, destination) => {
  segmentEvent("Navigation Clicked", {
    module_location: "navigation",
    type: "button",
    text,
    destination,
  })
}

//force navigate for ipad https://nutrafol.atlassian.net/browse/NT2-8512
const onTouchStart = (url) => {
  if (isIpad()) {
    navigate(url)
  }
}

const Navigation = ({ data, isMouseInHeader }) => {
  const [hoveredItem, setHoveredItem] = useState(null)
  const [hideSubMenu, setHideSubMenu] = useState(false)

  useEffect(() => {
    if (hideSubMenu) {
      setHideSubMenu(false)
    }
  }, [hideSubMenu])

  const navLinks = data.map((item, index) => {
    return (
      <React.Fragment key={`navLink-${index}`}>
        {!item.isMobileOnly && (
          <ListItem
            id={item.name}
            key={`navitem-${index}`}
            className={`has-subnav`}
            onMouseEnter={(e) => {
              if (!!item.children) {
                if (e.target.classList.contains("has-subnav")) {
                  trackExpanded(item.link.text)
                }
                addActiveItemClass(e.currentTarget)
              }
            }}
            onFocus={(e) => onFocus(e.currentTarget)}
            onBlur={(e) => onBlur(e.currentTarget)}
            onMouseLeave={(e) => removeActiveItemClass(e)}
            onClick={(e) => mobileArrowClick(e, item)}
            data-linkname={item.name}
          >
            {/* we need href="" for ADA */}
            <InternalNavLink
              href={item.link.url}
              role="button"
              aria-expanded="false"
              onClick={(e) => {
                removeActiveItemClass(e, true)
                if (item.link.url !== "#") {
                  trackClicked(item.link.text, item.link.url)
                  navigate(item.link.url)
                }
              }}
            >
              <>
                {item.link.text}{" "}
                {item.children ? (
                  <ArrowSection>
                    <DownArrow className="arrow-down" />
                  </ArrowSection>
                ) : null}{" "}
              </>
            </InternalNavLink>
            {!!item.children && !hideSubMenu && (
              <>
                <DropdownMenu
                  className={`dropdown ${
                    item.name === "shop"
                      ? "full-dropdown vegan-hide"
                      : "small-dropdown"
                  }`}
                >
                  <DropdownWrapper className="row">
                    <MainBox>
                      {item.children && (
                        <Flex>
                          <Box width={item.subCategories ? "70%" : "100%"}>
                            <Flex
                              flexWrap="wrap"
                              flexDirection="column"
                              pb={8}
                              pr={8}
                            >
                              {item.children.map((subitem, idx) => (
                                <Box
                                  width={item.subCategories ? 1 / 2 : "100%"}
                                  pl={8}
                                  pt={8}
                                  key={`nav-item-${idx}`}
                                  className={subitem?.className}
                                >
                                  <NavItem
                                    onTouchStart={() =>
                                      onTouchStart(subitem.url)
                                    }
                                    className={`${
                                      item.subCategories
                                        ? `${item.name}-nav-item`
                                        : ""
                                    } ${subitem.isMens ? "nav-item-mens" : ""}`}
                                  >
                                    <Link
                                      to={subitem.url}
                                      key={`subitem-${idx}`}
                                      onClick={() => {
                                        setHideSubMenu(true)
                                        isMouseInHeader.current = false
                                        trackClicked(subitem.name, subitem.url)
                                      }}
                                    >
                                      <SubItemWrapper
                                        onMouseEnter={() =>
                                          setHoveredItem(subitem.name)
                                        }
                                        onMouseLeave={() =>
                                          setHoveredItem(null)
                                        }
                                        onClick={removeActiveItemClass}
                                      >
                                        <SubItem
                                          className={`${subitem.name.toLowerCase()} ${buildCls(
                                            subitem,
                                            hoveredItem
                                          )}`}
                                        >
                                          <Flex alignItems="center">
                                            {subitem.image ? (
                                              <Box width="35%" mr={8}>
                                                <img
                                                  srcSet={`
                                                    ${subitem.image}w=130,
                                                    ${subitem.image}&w=260 2x,
                                                    ${subitem.image}&w=390 3x
                                                  `}
                                                  src={`${subitem.image}&w=260`}
                                                  className={`dropdown-image ${subitem.name}`}
                                                  alt=""
                                                  role="presentation"
                                                />
                                              </Box>
                                            ) : null}
                                            <Box flex={1}>
                                              <TextElement
                                                element="h4"
                                                text={subitem.name}
                                                className={`title--i text-center product-title`}
                                                styles={{
                                                  color:
                                                    theme.wild.color.master.base
                                                      .black,
                                                }}
                                              />
                                              {subitem.subtext ||
                                              subitem.subtextDesktop ? (
                                                <TextElement
                                                  element="p"
                                                  text={
                                                    subitem.subtextDesktop
                                                      ? subitem.subtextDesktop
                                                      : subitem.subtext
                                                  }
                                                  className="body--small subtext"
                                                  styles={{
                                                    color:
                                                      theme.wild.color.master
                                                        .base.black,
                                                  }}
                                                />
                                              ) : null}
                                              {subitem.isMens && (
                                                <ButtonWild
                                                  primary
                                                  textLink
                                                  arrowRight
                                                  className="mens-site-nav-button"
                                                  label="Switch to Nutrafol Men"
                                                />
                                              )}
                                            </Box>
                                          </Flex>
                                        </SubItem>
                                      </SubItemWrapper>
                                    </Link>
                                  </NavItem>
                                </Box>
                              ))}
                            </Flex>
                          </Box>
                          {item.subCategories && (
                            <Box width="30%">
                              <Flex flexDirection="column" pb={8}>
                                {item.subCategories.map((subCategory, idx) => (
                                  <Box p={8} pb={0} key={`sub-nav-item-${idx}`}>
                                    <SubNavItem
                                      onTouchStart={() =>
                                        onTouchStart(subCategory.url)
                                      }
                                    >
                                      <Link
                                        to={subCategory.url}
                                        key={`subcategory-${idx}`}
                                        onClick={() => {
                                          trackClicked(
                                            subCategory.name,
                                            subCategory.url
                                          )
                                        }}
                                        state={{ anchor: subCategory.anchor }}
                                      >
                                        <SubItemWrapper
                                          onMouseEnter={() =>
                                            setHoveredItem(subCategory.name)
                                          }
                                          onMouseLeave={() =>
                                            setHoveredItem(null)
                                          }
                                          onClick={removeActiveItemClass}
                                        >
                                          <SubItem
                                            className={`${subCategory.name.toLowerCase()} ${buildCls(
                                              subCategory,
                                              hoveredItem
                                            )}`}
                                          >
                                            <Flex alignItems="center">
                                              {subCategory.image ? (
                                                <Box width="35%" mr={15}>
                                                  <img
                                                    srcSet={`
                                                      ${subCategory.image}?w=110,
                                                      ${subCategory.image}?w=165 1.5x,
                                                      ${subCategory.image}?w=220 2x,
                                                      ${subCategory.image}?w=330 3x,
                                                    `}
                                                    src={`${subCategory.image}`}
                                                    className={`dropdown-image ${subCategory.name}`}
                                                    alt=""
                                                    role="presentation"
                                                  />
                                                </Box>
                                              ) : null}
                                              <Box flex={1}>
                                                <TextElement
                                                  element="h4"
                                                  text={subCategory.name}
                                                  className="title--small-i"
                                                  styles={{
                                                    color:
                                                      theme.wild.color.master
                                                        .base.black,
                                                  }}
                                                />
                                                <TextElement
                                                  element="p"
                                                  text={
                                                    subCategory.subtextDesktop
                                                      ? subCategory.subtextDesktop
                                                      : subCategory.subtext
                                                  }
                                                  className="body--small"
                                                  styles={{
                                                    color:
                                                      theme.wild.color.master
                                                        .base.black,
                                                  }}
                                                />
                                              </Box>
                                            </Flex>
                                          </SubItem>
                                        </SubItemWrapper>
                                      </Link>
                                    </SubNavItem>
                                  </Box>
                                ))}
                                <Box p={8} pb={0}>
                                  <ButtonWrapper>
                                    <ButtonWild
                                      primary
                                      label="See All Products"
                                      className="desktop-button"
                                      onClick={() => {
                                        trackClicked(
                                          "See All Products",
                                          "/products/"
                                        )
                                        navigate("/products/")
                                      }}
                                    />
                                  </ButtonWrapper>
                                </Box>
                              </Flex>
                            </Box>
                          )}
                        </Flex>
                      )}
                    </MainBox>
                  </DropdownWrapper>
                </DropdownMenu>
                {item.name === "shop" ? <NavigationShop data={data} /> : null}
              </>
            )}
          </ListItem>
        )}
      </React.Fragment>
    )
  })

  return (
    <Nav className="navigation">
      <NavUl className="master-nav-ul">{navLinks}</NavUl>
    </Nav>
  )
}

export default Navigation
